import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import createAssembly from './createAssembly';
import createTracks from "./createTracks";
import createDefaultSession from "./createDefaultSession";
import { CurrentSession } from './CurrentSession';
import JBrowseService from "../services/jbrowse.service";
import { Listbox } from '@headlessui/react';
import EventBus from "../common/EventBus";
import VcfModal from "./VcfModal";
import ToolsModal from "./ToolsModal";
import _ from 'underscore';
import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import BuildIcon from '@mui/icons-material/Build';
import ListIcon from '@mui/icons-material/List';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteIcon from '@mui/icons-material/Note';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SaveSessionModal from "./SaveSessionModal";
import PanGenomeModal from "./PanGenomeModal";
import WarningIcon from '@mui/icons-material/Warning';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { FormControlLabel, FormGroup } from '@mui/material';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import HandymanIcon from '@mui/icons-material/Handyman';
import { form } from "react-validation/build/form";

function WizardTool() {
    const [selectedCrop, setSelectedCrop] = useState("");
    const [selectedAssembly, setSelectedAssembly] = useState("");
    const [selectedTracks, setSelectedTracks] = useState([]);
    const [selectedPaf, setSelectedPaf ] = useState([]);
    const [availableAssemblies, setAvailableAssemblies] = useState([]);
    const [availableTracks, setAvailableTracks] = useState([]);
    const [availablePaf, setAvailablePaf ] = useState([]);
    const [projects, setProjects] = useState([]);
    const [crops, setCrops] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentAssembly, setCurrentAssembly] = useState(null);
    const [currentTracks, setCurrentTracks] = useState(null);
    const [currentSession, setCurrentSession] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null); // Selected project
    const [files, setFiles] = useState([]); // Files associated with selected project
    const [selectedFiles, setSelectedFiles] = useState([]); // User selected files
    const [bamError, setBamError] = useState(false); // Error state
    const [isWizardVisible, setIsWizardVisible] = useState(true);
    const [chromosomes, setChromosomes] = useState([]);
    const [currentChromosome, setCurrentChromosome] = useState("");
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [accessVariantIsOpen, setAccessVariantIsOpen] = useState(false);
    const [blastModalIsOpen, setBlastModalIsOpen] = useState(false);
    const [blastData, setBlastData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sampleNames, setSampleNames] = useState([]);
    const [notesIsOpen, setNotesIsOpen] = useState(false);
    const [notes, setNotes] = useState([]);
    const [vcfModalIsOpen, setVcfModalIsOpen] = useState(false);
    const [selectedVcfSamples, setSelectedVcfSamples] = useState([]);
    const [currentImage, setCurrentImage] = useState("");
    const [location, setLocation] = useState("");
    const [selectedVcf, setSelectedVcf ] = useState(false);
    const [saveAlert, setSaveAlert] = useState({
        visible: false,
        type: '', // 'success' or 'error'
        message: ''
    });
    const [state, setState] = useState(null);
    const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
    const [showLiteratureSearch, setShowLiteratureSearch ] = useState(false);
    const [toggleLiteratureSearch, setToggleLiteratureSearch ] = useState(false);
    const [literatureModalIsOpen, setLiteratureModalIsOpen] = useState(false);
    const [panGenomeModalIsOpen, setPanGenomeModalIsOpen ] = useState(false);
    const [ vcfWarning, setVcfWarning] = useState( false );
    const [alignmentLoading, setAlignmentLoading ] = useState(false );
    const [ alignmentError, setAlignmentError ] = useState( false );
    const [ showToolsModal, setShowToolsModal ] = useState( false );

    const { user: currentUser } = useSelector((state) => state.auth);
    // console.log("current user: ", currentUser );

    const imgSrc = require("../img/jbrowse.png");

    // Init - Get Crops
    useEffect(() => {
        if (currentUser) {
            // Get Plants
            JBrowseService.getPlants(currentUser.email).then(
                (response) => {
                    // console.log("plant response: ", response);
                    var filtered = _.filter(response, (value) => {
                        value.displayName = capitalizeFirstLetter(value.CommonName);
                        return value;
                    });
                    let sorted = _.sortBy(filtered, 'displayName');
                    // console.log("got crops: ", sorted);
                    setCrops(sorted);
                    setSelectedCrop("");
                    setSelectedVcf( false );
                    setSelectedPaf([]);
                },
                (error) => {
                    console.log("error: ", error);
                    if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_REQUEST") {
                        console.warn("logout!!!");
                        EventBus.dispatch("logout");
                    }
                }
            );

        } else {
            console.log("no user found..");
        }
    }, [currentUser]);

    useEffect(() => {
        // setAvailableAssemblies(assemblies[selectedCrop]);
        setSelectedAssembly("");
        setSelectedTracks([]);
        // console.log("got crop...", availableAssemblies);
    }, [selectedCrop]);

    useEffect(() => {
        // setAvailableTracks(tracks[selectedAssembly]);
        setSelectedTracks([]);
        // console.log("assembly: ", selectedAssembly );
        // Send Request
        if (selectedAssembly !== "") {
            let obj = {
                company: currentUser.DirectoryName,
                assembly: selectedAssembly
            };
            JBrowseService.getChromosomes( obj ).then(
                (response) => {
                    // console.log("chromosome response: ", response);
                    var arr = parsePythonList(response.result)
                    // console.log("type: ", typeof arr )
                    // console.log('arr: ', arr );
                    setChromosomes(arr);
                    setCurrentChromosome(arr[0]);

                },
                (error) => {
                    console.log("error: ", error);
                }
            )
        }

    }, [selectedAssembly]);

    useEffect(() => {
        if (selectedProject && selectedProject.Company_Code && selectedProject.Code) {
            var obj = {
                company: selectedProject.Company_Code,
                code: selectedProject.Code,
                assembly: selectedAssembly,
                user: currentUser.email
            };
            // Send Request
            JBrowseService.getBamFiles(obj).then(
                (response) => {
                    console.log("bam response: ", response);
                    if (response.status === 0) {
                        setFiles(response.result);
                        setBamError(false); // Reset error state
                    } else {
                        setBamError(true); // Set error state if no files are found
                        setTimeout(function () {
                            setBamError(false);
                        }, 3000);
                    }
                },
                (error) => {
                    console.log("bam error: ", error);
                }
            )
        }
    }, [selectedProject]);

    useEffect( () => {
        if( selectedTracks.length > 0 ) {
            console.log("tracks: ", selectedTracks );
            const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));
            console.log("vcf: ", vcfFiles );
            if( vcfFiles.length > 0 ) {
                console.log("user selected vcf.");
                setSelectedVcf( true )
            }
        }
    }, [selectedTracks] );

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setIsOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleCropChange = (value) => {
        console.log("crop: ", value);
        setSelectedCrop(value);
        let assembly = {}
        assembly[value] = [];

        // Image 
        _.each(crops, (val) => {
            if (val.displayName === value) {
                setCurrentImage(val.WebImage);
            }
        });

        var obj = {
            crop: value,
            user: currentUser.username
        };
        // Get Assemblies
        JBrowseService.getAssemblies(obj).then(
            (response) => {
                console.log("assemblies response: ", response);
                var options = _.filter(response, function (value) {
                    return value.Name;
                });
                let sorted = _.sortBy(options, 'Name');
                assembly[value] = sorted

                // console.log("assembly: ", assembly);
                // Update state based on the response
                setAvailableAssemblies(assembly[value]);
            },
            (error) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )
    };

    const handleAssemblyChange = (value) => {
        setSelectedAssembly(value);
        let tracks = {}
        tracks[value] = [];
        let file = value;
        // console.log("file: ", file);
        console.log("tracks: ", tracks);
        var obj = {
            assembly: value,
            user: currentUser.username
        };
        // Send Request
        JBrowseService.getTracks(obj).then(
            (response) => {
                console.log("response: ", response);
                // Get file name from file path 
                if (response.gffs[0] != undefined) {
                    // console.log("response: ", response.data.gffs );
                    // console.log("found gff len", response.data.gffs.length );

                    // Set Current Genome Id
                    let currentGenomeId = response.gffs[0].idReferenceGenome;
                    let GFFs = [];

                    if (response.gffs.length > 1) {
                        _.each(response.gffs, function (value) {
                            // console.log("value: ", value );
                            if (value.GFF_FilePath) {
                                value.GFF_FileName = getFileName(value.GFF_FilePath);
                                value.FileName = value.GFF_FileName;
                                GFFs.push(value);
                            }
                        });
                    } else {
                        if (response.gffs[0].GFF_FilePath) {
                            response.gffs[0].GFF_FileName = getFileName(response.gffs[0].GFF_FilePath);
                            response.gffs[0].FileName = response.gffs[0].GFF_FileName;
                            GFFs.push(response.gffs[0]);
                        }
                    }
                    // console.log("found gff array", $scope.GFFs );

                    // Remove Dulicate GFF3s, peach/almond issues 
                    var uniqueList = _.uniq(GFFs, function (item, key, GFF_FileName) {
                        return item.GFF_FileName;
                    });
                    // console.log("unique: ", uniqueList );
                    GFFs.forEach((obj) => {
                        tracks[file.toString()].push(obj);
                    });
                    // tracks[ file ] = [...tracks, ...GFFs];
                    // $scope.trackCount += $scope.GFFs.length;
                    // if(response.data.gffs[0].GFF_FilePath != null) {
                    //     console.log("found good gffs")
                    //     $scope.currentGenomeId = response.data.gffs[0].idReferenceGenome;
                    //     response.data.gffs[0].GFF_FileName = getFileName( response.data.gffs[0].GFF_FilePath );
                    //     $scope.GFFs = response.data.gffs[0];
                    // }

                }

                if (response.vcfs[0] != undefined) {
                    // console.log("found vcfs", response.vcfs );
                    // console.log("found len", response.vcfs.length );
                    // $scope.VCFs = response.vcfs[1];

                    // Set Current Genome Id 
                    let currentGenomeId = response.vcfs[0].ReferenceGenome_idReferenceGenome;
                    let VCFs = [];

                    // Find Archived Files 
                    if (response.vcfs.length > 1) {
                        // $scope.VCFs = [];
                        _.each(response.vcfs, function (value) {
                            // console.log("value: ", value );
                            if (value.Active == 1) {
                                console.warn("found archived files..");
                                let archiveBool = true;
                                // archivedVcfs.push( value );
                            } else {
                                value.FileName = value.VCF_FileName;
                                VCFs.push(value);
                            }
                        });
                    } else {
                        // $scope.VCFs = [];
                        VCFs.push(response.vcfs[0]);
                    }

                    VCFs.forEach((obj) => {
                        tracks[file.toString()].push(obj);
                    });
                    // tracks[ file ] = [...tracks, ...VCFs];
                    // tracks.concat( VCFs );
                    // let trackCount = VCFs.length;

                }

                let sorted = _.sortBy(tracks[file.toString()], 'FileName');
                // console.log("after: ", sorted);
                setAvailableTracks(sorted);

            },
            (error) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )
      
    };

    const handleTrackChange = (value) => {
        if (!selectedTracks.includes(value)) {
            // Check if track is a vcf 
            let vcf_check = value.includes(".vcf");
            if (vcf_check) {
                setLoading(true);
                // Get Samples, then show modal 
                let obj = {
                    file: value,
                    ref: selectedAssembly,
                    user: currentUser.email,
                    company: currentUser.DirectoryName
                }
                console.log("obj: ", obj);
                // Send Request
                JBrowseService.getSampleNames(obj).then(
                    (response) => {
                        console.log("samples response: ", response);
                        let samples = response.result.output;
                        if( samples[0] === "An error occurred") {
                            console.log('bad vcf file..');
                            setLoading(false);
                            setVcfWarning( true );
                            setTimeout( () => {
                                setVcfWarning( false );
                            },3000 );
                        } else {
                            let updated_samples = _.map( samples, ( value ) => {
                                // console.log("value ", value );
                                return value+"_"+response.idVCFFile;
                            });
                            // console.log("updated: ", updated_samples );

                            setSelectedTracks([...selectedTracks, value]);
                            setSampleNames( updated_samples );
                            setVcfModalIsOpen(true);
                            setLoading(false);
                        }

                    },
                    (error) => {
                        console.log('error: ', error);
                        setLoading(false);
                        if (error.code === "ERR_NETWORK") {
                            console.warn("logout!!!");
                            EventBus.dispatch("logout");
                        }
                    }
                )
            } else {
                setSelectedTracks([...selectedTracks, value]);
            }

        } else {
            alert('This track has already been selected. Please choose a different track.');
        }
    };

    const handleBuildClick = () => {
        setLoading(true)
        setIsWizardVisible(false);

        const myAssembly = createAssembly(selectedAssembly);
        // console.log("assembly config: ", myAssembly);
        setCurrentAssembly(myAssembly);

        console.log('selected: ', selectedTracks);
        const filteredTracks = selectedTracks.filter(file => !file.endsWith('notes.gff'));

        // return;
        // if( selectedPaf.length > 0 ) {
        //     console.log("found selected paf: ", selectedPaf );
        //     filteredTracks.push( selectedPaf[0].PAFFile );
        // }

        // return;

        (async () => {
            const myTracks = await createTracks(selectedAssembly, filteredTracks );
            console.log('tracks: ', myTracks);
            setCurrentTracks(myTracks);
        })();
        // const myTracks = createTracks(selectedAssembly, selectedTracks);
        // console.log('tracks: ', myTracks);
        // setCurrentTracks(myTracks);

        const defaultSession = createDefaultSession(selectedAssembly, filteredTracks);
        console.log("defaultSession: ", defaultSession);
        setCurrentSession(defaultSession);
        // return;

        // Get Notes
        let notes_obj = {
            idCompany: currentUser.idCompany,
            username: currentUser.email,
            assembly: selectedAssembly,
        };
        console.log("notes obj: ", notes_obj);

        // Send Request
        JBrowseService.getNotes(notes_obj).then(
            (response) => {
                console.log("notes response: ", response);
                let notes = []
                // Loop through notes results and fix url strings
                _.each(response.result, function (value) {
                    var obj = value;
                    obj.created_at = dayjs(value.created_at).format('MMMM D YYYY, h:mm:ss A');
                    notes.push(obj);
                });
                // console.log("notes: ", notes );
                setNotes(notes);
            },
            (error) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )

        // Send Request
        JBrowseService.destroyBlastDirectory({ user: currentUser.email }).then(
            (response) => {
                console.log("destroy response: ", response);
            },
            (error) => {
                console.log("error: ", error);
            }
        )

    }

    const handleProjectChange = (e) => {
        const projectId = e.target.value;
        const project = projects.find(p => p.idProject == projectId);
        setSelectedProject(project);
        setSelectedFiles([]); // Reset selected files when project changes
    };

    const handleBamChange = (e) => {
        console.log("file: ", e.target.value);
        if (e.target.checked) {
            setSelectedFiles(prevFiles => [...prevFiles, e.target.value]);
            setSelectedTracks([...selectedTracks, e.target.value]);
        } else {
            setSelectedFiles(prevFiles => prevFiles.filter(fileId => fileId !== e.target.value));
            setSelectedTracks(prevTracks => prevTracks.filter(fileId => fileId !== e.target.value));
        }
    };

    const removeTrack = (track, listName) => {
        if (listName === 'selectedTracks') {
            // console.log("track: ", track );
            setSelectedTracks(prevTracks => prevTracks.filter(t => t !== track));
            setSelectedVcfSamples(prevTracks => prevTracks.filter(t => t !== track ));
        } else if (listName === 'selectedFiles') {
            setSelectedFiles(prevFiles => prevFiles.filter(f => f !== track));
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedProject(null);
    }

    const openModal = () => {
        console.log("current User: ", currentUser );
        setIsOpen(true);
        setAlignmentLoading( true );
        // Send Request 
        // Get Bam Files
        let bamObj = {
            user: currentUser.email,
            company: currentUser.DirectoryName,
            idCompany: currentUser.idCompany,
            assembly: selectedAssembly,
        };
        console.log("obj: ", bamObj);

        JBrowseService.getLocalBamFiles(bamObj).then(
            (response) => {
                console.log("local bam response: ", response);
                let formattedResponse = response.result.map( file => {
                    let obj = {
                        filepath: response.path+file,
                        filename: file
                    }
                    return obj;
                });
                console.log("formmated: ", formattedResponse );
                setFiles( formattedResponse );
                setAlignmentLoading( false );
            },
            (error) => {
                console.log('error: ', error);
                setAlignmentLoading( false );
                setAlignmentError( true );
                setTimeout( ()=> {
                    setAlignmentError( false );
                },5000);
            }
        )
        // let obj = {
        //     user: currentUser.email,
        //     company: currentUser.company,
        //     assembly: selectedAssembly
        // };
        // console.log("obj: ", obj );
        // JBrowseService.getAvailableBamFiles( obj ).then(
        //     (response) => {
        //         console.log("bam response: ", response);
        //         // Post-processing the response to filter out .bai files and get file names
        //         const processedResponse = response.map(project => ({
        //             ...project,
        //             files: project.files
        //                 .filter(file => file.endsWith('.bam')) // Keep only .bam files
        //                 .map(file => ({
        //                     filepath: file,
        //                     filename: file.split('/').pop()
        //                 })) // Create an object with filepath and filename
        //         }));
        //         console.log("proccess: ", processedResponse );

        //         setFiles(processedResponse);
        //     },
        //     (error) => {
        //         console.log("error: ", error);
        //     }
        // )

        // JBrowseService.getProjects({ id: currentUser.idCompany }).then(
        //     (response) => {
        //         // console.log("response: ", response);
        //         setProjects(response);
        //     },
        //     (error) => {
        //         console.log("projects error: ", error);
        //     }
        // )
    }

    const toggleWizard = () => {
        setIsWizardVisible(!isWizardVisible);
    };

    const toggleVariants = () => {
        // console.log("got here: ", accessVariantIsOpen );
        setAccessVariantIsOpen(true);
    }

    const toggleNotes = () => {
        setNotesIsOpen(true);
    }

    const toggleBlast = () => {
        setBlastModalIsOpen(true);
    }

    const toggleSavedSession = () => {
        setSaveModalIsOpen( true )
    }

    const destroySession = () => {
        setState( null );
        setIsWizardVisible( true );
        setSelectedVcfSamples([]);
        setCurrentSession(null);
        setSelectedVariants([]);
        setSelectedAssembly("");
        setSelectedTracks([]);
        setSelectedCrop("");
        setSelectedFiles([]);
        setBlastData([]);
        setNotes([]);
        setToggleLiteratureSearch( false );
        setShowLiteratureSearch( false );
        setSelectedVcf( false );
        setAvailablePaf([]);
        setSelectedPaf([]);
    }

    const handleLiteratureSearch = () => {
        setToggleLiteratureSearch( true );
        setLiteratureModalIsOpen( true );
    };

    if (!currentUser) {
        console.warn("got here and transitioned ")
        return <Navigate to="/" />;
    }

    return (
        <>
            {loading && (
                <div className='loading'></div>
            )}
            {/* Buttons */}
            <div className="container-fluid">
                {/* Toggle View */}
                {currentSession && (
                    <>
                        {/* <button
                            onClick={toggleWizard}
                            className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800"
                            style={{ paddingLeft: "0px" }}
                        >
                            <VisibilityIcon style={{ marginRight: '8px' }} />
                            {isWizardVisible ? 'Hide Wizard Tool' : 'Show Wizard Tool'}
                        </button> */}
                        
                        <button
                            onClick={destroySession}
                            className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                        >
                            <DeleteForeverIcon style={{ marginRight: '8px' }} />
                            Destroy Session
                        </button>

                        <button
                            onClick={toggleSavedSession}
                            className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                        >
                            <SaveIcon style={{ marginRight: '8px' }} />
                            Save Session
                        </button>
                    </>
                )}

                {notes.length > 0 && (
                    <button
                        onClick={toggleNotes}
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <NoteIcon style={{ marginRight: '8px' }} />
                        Notes
                    </button>
                )}

                {currentSession && (
                    <button
                        onClick={toggleVariants}
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <ListAltIcon style={{ marginRight: '8px' }} />
                        Access Variants
                    </button>
                )}

                {currentSession && (
                    <button
                        onClick={toggleBlast}
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <SearchIcon style={{ marginRight: '8px' }} />
                        Blast
                    </button>
                )}

                {currentSession && (
                    <>
                    <button
                        onClick={ () => { setShowToolsModal( true )} }
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <HandymanIcon style={{ marginRight: '8px' }} />
                        Tools
                    </button>
                    </>
                )}
               
                {/* {currentSession && (
                    <>
                    <button
                        onClick={ handleLiteratureSearch }
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <MenuBookIcon style={{ marginRight: '8px' }} />
                        Literature Search
                    </button>
                    </>
                )} */}
            {/* <p className="text-white">{toggleLiteratureSearch ? 'True' : 'False'}</p> */}

               
            </div>

            {saveAlert.visible && (
                <div className={`saveAlert fixed top-4 left-1/2 transform -translate-x-1/2 p-4 rounded-lg shadow-2xl ${saveAlert.type === 'success' ? 'bg-green-600' : 'bg-red-600'} text-white z-50 transition-transform duration-300`}>
                    <span className="block mr-4">{saveAlert.message}</span>
                    <button className="absolute top-2 right-2 focus:outline-none" onClick={() => setSaveAlert({ ...saveAlert, visible: false })}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414L11.414 13l2.293 2.293a1 1 0 01-1.414 1.414L10 14.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 13 6.293 10.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            )}

            {/* Wizard Tool */}
            <AnimatePresence>
                {isWizardVisible && (
                    <motion.div
                        initial={{ opacity: 0, x: '-100%' }}
                        animate={{ opacity: 1, x: '0%' }}
                        exit={{ opacity: 0, x: '-100%' }}
                        transition={{ duration: 0.5 }}
                        className="bg-gray-500"
                    >
                        <div className="`transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}` z-10" style={{ zIndex: 100000 }}>
                            <div className="flex flex-col items-start justify-center p-10 bg-gray-100 w-full ">
                                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full">
                                    <div className="flex flex-col w-full sm:w-3/6 space-y-4">
                                        <div className="mb-6">
                                            <img src={imgSrc} alt="logo" className="h-12 mr-4" />
                                            <h1 className="text-2xl font-semibold text-gray-700">JBrowse 2 Wizard</h1>
                                            <p className="text-sm text-gray-600 italic">developed by Verinomics</p>
                                        </div>

                                        {/* Crop Selection */}
                                        <label htmlFor="plant-dropdown" className="block text-sm font-medium text-gray-700" style={{ margin: "0px" }}>Crop</label>
                                        <Listbox value={selectedCrop} onChange={handleCropChange} id="plant-dropdown">
                                            {({ open }) => (
                                                <>
                                                    <div className="relative">
                                                        <Listbox.Button className="block w-full py-2 pl-3 pr-10 text-base bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 shadow-sm transition duration-150 ease-in-out sm:text-sm flex justify-between items-center">
                                                            {selectedCrop || "Select Crop"}
                                                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                            </svg>
                                                        </Listbox.Button>
                                                        <Listbox.Options className="absolute w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-20">
                                                            {crops.map((crop, index) => (
                                                                <Listbox.Option key={index} value={crop.displayName}>
                                                                    {({ active, selected }) => (
                                                                        <div
                                                                            className={`cursor-pointer select-none relative px-4 py-2 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                                                }`}
                                                                        >
                                                                            <div className={`cursor-pointer select-none relative px-2 py-1 flex justify-between items-center ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                                                }`}>
                                                                                <span>{crop.displayName}</span>
                                                                                {selected && (
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="currentColor"
                                                                                        aria-hidden="true"
                                                                                    >
                                                                                        <path d="m9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414z" />
                                                                                    </svg>
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                                </Listbox.Option>
                                                            ))}
                                                        </Listbox.Options>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>

                                        {/* Assembly Selection */}
                                        {selectedCrop && availableAssemblies && (
                                            <div>
                                                <label htmlFor="assembly-dropdown" className="block text-sm font-medium text-gray-700">Assembly</label>
                                                <Listbox value={selectedAssembly} onChange={handleAssemblyChange} id="assembly-dropdown">
                                                    {({ open }) => (
                                                        <div className="relative mt-1">
                                                            <Listbox.Button className="block w-full py-2 pl-3 pr-10 text-base bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 shadow-sm transition duration-150 ease-in-out sm:text-sm flex justify-between items-center">
                                                                {selectedAssembly || "Select Assembly"}
                                                                <span className="text-gray-400">
                                                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                    </svg>
                                                                </span>
                                                            </Listbox.Button>
                                                            <Listbox.Options className="absolute w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-20">
                                                                {availableAssemblies.map((assembly, index) => (
                                                                    <Listbox.Option key={index} value={assembly.Name} className="truncate">
                                                                        {({ active, selected }) => (
                                                                            <div className={`cursor-pointer select-none relative px-4 py-2 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'}`}>
                                                                                <span className="block truncate">{assembly.Name}</span>
                                                                                {selected && (
                                                                                    <span className={`absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                            <path d="m9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </div>
                                                    )}
                                                </Listbox>
                                            </div>
                                        )}

                                        {/* Track Selection */}
                                        {selectedAssembly && availableTracks && (
                                            <div>
                                                <label htmlFor="track-dropdown" className="block text-sm font-medium text-gray-700">Tracks</label>

                                                <Listbox value={selectedTracks} onChange={handleTrackChange} id="track-dropdown" className="">
                                                    {({ open }) => (
                                                        <div className="relative mt-1">
                                                            <Listbox.Button className="block w-full py-2 pl-3 pr-10 text-base bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 shadow-sm transition duration-150 ease-in-out sm:text-sm flex justify-between items-center">
                                                                {selectedTracks.length > 1 ? `${selectedTracks[0]} + ${selectedTracks.length - 1} more` : selectedTracks[0] || "Select Tracks"}
                                                                <span className="text-gray-400">
                                                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                    </svg>
                                                                </span>
                                                            </Listbox.Button>
                                                            <Listbox.Options className="absolute w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                                                                {availableTracks.map((track, index) => (
                                                                    <Listbox.Option key={index} value={track.FileName} className="truncate">
                                                                        {({ active, selected }) => (
                                                                            <div className={`cursor-pointer select-none relative px-4 py-2 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'}`}>
                                                                                <span className="block truncate">{track.FileName}</span>
                                                                                {selected && (
                                                                                    <span className={`absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                            <path d="m9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </div>
                                                    )}
                                                </Listbox>
                                            </div>
                                        )}

                                        {/* Build & Alignment  */}
                                        {selectedAssembly && selectedTracks.length > 0 && (
                                            <div className="flex space-x-4 mt-4">
                                                <button className="flex items-center w-full sm:w-auto px-5 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 focus:ring-2 focus:ring-indigo-400 transition duration-150" onClick={handleBuildClick}>
                                                    <BuildIcon style={{ marginRight: '8px' }} />
                                                    Build JBrowse
                                                </button>
                                                <button onClick={openModal} className="flex items-center w-full sm:w-auto px-5 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-900 focus:outline-none focus:bg-indigo-600 focus:ring-2 focus:ring-gray-400 transition duration-150">
                                                    <ListIcon style={{ marginRight: '8px' }} />
                                                    Alignment Tracks
                                                </button>
                                            </div>
                                        )}
                                        
                                        {/* VCF Samples */}
                                        { selectedVcf && (
                                            <div className="flex space-x-4 mt-4">
                                                <button className="flex items-center w-full sm:w-auto px-5 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 focus:ring-2 focus:ring-blue-400 transition duration-150" onClick={() => { setVcfModalIsOpen(true) }}>
                                                    <InsertDriveFileIcon style={{ marginRight: '8px' }} />
                                                    VCF Samples
                                                </button>
                                            </div>
                                        )}
                                        
                                        {/* Pan Genome */}
                                        {/* {selectedAssembly && selectedTracks.length > 0 && (
                                            <div className="flex space-x-4 mt-4">
                                                <button className="flex items-center w-full sm:w-auto px-5 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 focus:ring-2 focus:ring-indigo-400 transition duration-150" onClick={() => { setPanGenomeModalIsOpen(true) }}>
                                                    <CompareArrowsIcon style={{ marginRight: '8px' }} />
                                                    Select Pan Genome
                                                </button>
                                            </div>
                                        )} */}

                                        {vcfWarning && (
                                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                                <WarningIcon style={{marginRight: "5px"}}/>
                                                There was an error with that VCF file. Contact a Verinomics engineer.
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                        )}

                                    </div>
                                    {/* Display Current Session */}
                                    <div className="flex flex-col w-full sm:w-3/6 sm:mt-0 text-gray-800 space-y-2 whitespace-normal flex-wrap">
                                        {selectedCrop && (
                                            <>
                                            <div className="d-flex mb-2 float-right">
                                                <img src={currentImage} alt="WebImage" style={{ maxHeight: "50px", maxWidth: "50px" }} />
                                            </div>
                                            <div className="font-weight-bold">Selected Crop: <span className="font-weight-normal">{selectedCrop}</span></div>
                                            </>
                                        )}
                                        {selectedAssembly && (
                                            <div className="font-weight-bold mb-2">Selected Assembly: <span className="font-weight-normal">{selectedAssembly}</span></div>
                                        )}
                                        {selectedTracks.length > 0 && (
                                            <div>
                                                <div className="font-weight-bold mb-2">Selected Tracks:</div>
                                                <List>
                                                    {selectedTracks
                                                        .filter(track => !track.endsWith('.bam'))
                                                        .map((track, index) => {
                                                            const fileName = track.split('/').pop();
                                                            return (
                                                                <ListItem key={index} className="bg-light rounded mb-2">
                                                                    <ListItemText primary={fileName} />
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="delete" onClick={() => removeTrack(track, 'selectedTracks')}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            );
                                                        })}
                                                </List>
                                            </div>
                                        )}
                                        {selectedFiles.length > 0 && (
                                            <div>
                                                <div className="font-weight-bold mb-2">Alignment Tracks:</div>
                                                <List>
                                                    {selectedFiles.map((track, index) => (
                                                        <ListItem key={index} className="bg-light rounded mb-2">
                                                            <ListItemText primary={track} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" aria-label="delete" onClick={() => removeTrack(track, 'selectedFiles')}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                        )}
                                        {selectedPaf.length > 0 && (
                                            <div>
                                                <div className="font-weight-bold mb-2">Synteny Tracks:</div>
                                                <List>
                                                    {selectedPaf.map((track, index) => (
                                                        <ListItem key={index} className="bg-light rounded mb-2">
                                                            <ListItemText primary={track.PAFFile} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                        )}
                                    </div>

                                 
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* JBrowse2 Session */}
            <CurrentSession
                assembly={currentAssembly}
                tracks={currentTracks}
                selectedTracks={selectedTracks}
                session={currentSession}
                setCurrentSession={setCurrentSession}
                chromosome={currentChromosome}
                selectedAssembly={selectedAssembly}
                setCurrentTracks={setCurrentTracks}
                currentUser={currentUser}
                selectedVariants={selectedVariants}
                setSelectedVariants={setSelectedVariants}
                accessVariantIsOpen={accessVariantIsOpen}
                setAccessVariantIsOpen={setAccessVariantIsOpen}
                blastModalIsOpen={blastModalIsOpen}
                setBlastModalIsOpen={setBlastModalIsOpen}
                blastData={blastData}
                setBlastData={setBlastData}
                loading={loading}
                setLoading={setLoading}
                sampleNames={sampleNames}
                setSampleNames={setSampleNames}
                notesIsOpen={notesIsOpen}
                setNotesIsOpen={setNotesIsOpen}
                notes={notes}
                setNotes={setNotes}
                createTracks={createTracks}
                createDefaultSession={createDefaultSession}
                setSelectedTracks={setSelectedTracks}
                location={location}
                setLocation={setLocation}
                state={state}
                setState={setState}
                selectedCrop={selectedCrop}
                setShowLiteratureSearch={setShowLiteratureSearch}
                toggleLiteratureSearch={toggleLiteratureSearch}
                setToggleLiteratureSearch={setToggleLiteratureSearch}
                chromosomes={chromosomes}
                availableAssemblies={availableAssemblies}
                literatureModalIsOpen={literatureModalIsOpen}
                setLiteratureModalIsOpen={setLiteratureModalIsOpen}
                setShowToolsModal={setShowToolsModal}
            />

            {vcfModalIsOpen && (
                <VcfModal
                    vcfModalIsOpen={vcfModalIsOpen}
                    setVcfModalIsOpen={setVcfModalIsOpen}
                    sampleNames={sampleNames}
                    selectedVcfSamples={selectedVcfSamples}
                    setSelectedVcfSamples={setSelectedVcfSamples}
                    selectedTracks={selectedTracks}
                    setSelectedTracks={setSelectedTracks}
                />
            )}

            {saveModalIsOpen && (
                <SaveSessionModal 
                    saveModalIsOpen={saveModalIsOpen}
                    setSaveModalIsOpen={setSaveModalIsOpen}
                    currentSession={currentSession}
                    setCurrentSession={setCurrentSession}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    currentAssembly={currentAssembly}
                    currentTracks={currentTracks}
                    location={location}
                    setSaveAlert={setSaveAlert}
                    selectedCrop={selectedCrop}
                />
            )}

            { panGenomeModalIsOpen && (
                <PanGenomeModal 
                    setPanGenomeModalIsOpen={setPanGenomeModalIsOpen}
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    setAvailablePaf={setAvailablePaf}
                    availablePaf={availablePaf}
                    selectedAssembly={selectedAssembly}
                    setSelectedPaf={setSelectedPaf}
                    selectedPaf={selectedPaf}
                />
            )}

            {isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto" style={{ zIndex: "1000" }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <div
                            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="my-modal-title"
                            aria-describedby="my-modal-description"
                        >
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" style={{ overflow: 'auto', height: "500px" }}>
                                <button onClick={closeModal} className="float-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600 hover:text-gray-900 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4" id="my-modal-title">
                                    Available Alignment Tracks
                                </h3>

                                { alignmentError && (
                                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning">
                                        No alignment files found for that assembly.
                                    </Alert>
                                )}

                                { alignmentLoading && (
                                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                )}

                                <ul className="list-unstyled ml-4">
                                    {files.map((file, index) => (
                                        <li key={index} className="mb-2">
                                            <div className="rounded bg-light d-flex align-items-center">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                            value={file.filename}
                                                            checked={selectedFiles.includes(file.filename) || false}
                                                            onChange={handleBamChange}
                                                            />
                                                        }
                                                        label={file.filename}
                                                        />
                                                </FormGroup>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                {/* Old Stuff Here */}
                                {/* {files.map(project => (
                                    <div key={project.directory} className="mb-4">
                                        <h3 className="mb-1 font-weight-bold">{project.projectName}</h3>
                                        <ul className="list-unstyled ml-4">
                                            {project.files.map((file, index) => (
                                                <li key={file} className="mb-2">
                                                    <div className="rounded bg-light d-flex align-items-center">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        value={file.filename}
                                                                        checked={selectedFiles.includes(file.filename) || false}
                                                                        onChange={handleBamChange}
                                                                    />
                                                                }
                                                                label={file.filename}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))} */}
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    onClick={closeModal}
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        {/* <Button variant='contained' onClick={()=> {setShowToolsModal(true)} }>Open modal</Button> */}

            { showToolsModal && (
                <ToolsModal 
                    setLiteratureModalIsOpen={setLiteratureModalIsOpen}
                    setShowToolsModal={setShowToolsModal}
                    showToolsModal={showToolsModal}
                    currentAssembly={currentAssembly}
                    currentUser={currentUser}
                    chromosomes={chromosomes}
                    selectedCrop={selectedCrop}
                />
            )}

        </>
    );
}

export default WizardTool;


/** Useful Functions */
function getFileName(filePath) {
    var text = filePath;
    var parts = text.split('/');
    var name = parts.pop();
    return name;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function parsePythonList(str) {
    // Remove the leading and trailing square brackets and split by comma
    var arr = str.slice(1, -1).split(',');

    // Trim leading and trailing spaces and single quotes from each array element
    arr = arr.map(item => item.trim().slice(1, -1));

    return arr;
}

