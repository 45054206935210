import React, { useState, useEffect, useRef } from 'react';
import JBrowseService from '../services/jbrowse.service';
import _ from 'underscore';
import { TextField, Button, Box, MenuItem } from '@mui/material';


const EditSessionModal = (props) => {
    // console.log("props: ", props );
    const { setLoading,
        currentUser,
        selectedCrop,
        setShowEditModal,
        selectedSession,
        setSelectedSession,
        setCurrentSessions,
        errorEditMessage,
        setErrorEditMessage,
        successEditMessage,
        setSuccessEditMessage,
        chromosomes,
    } = props;

    const [formData, setFormData] = useState({
        name: selectedSession.session.name,
        location: selectedSession.location
    });

    const [chromosome, setChromosome] = useState(chromosomes[0]);
    const [start, setStart] = useState('');
    const [stop, setStop] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleChromosomeChange = (event) => {
        setChromosome(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const location = `${chromosome}:${start}..${stop}`;
        // console.log('Location:', location);
        
        // let session = selectedSession;
        // session.session.name = formData.name;
        // session.location = formData.location;

        // Create a new object based on selectedSession with necessary modifications
        let updatedSession = {
            ...selectedSession, // Spread operator to copy properties from selectedSession
            session: {
                ...selectedSession.session,
                name: formData.name // Update name with formData.name
            },
            location: location // Update location directly on updatedSession
        };
        console.log("updated: ", updatedSession);
        setSelectedSession(updatedSession);

        // Send Request
        JBrowseService.updateSessionForUser(updatedSession).then(
            (response) => {
                console.log("response: ", response);
                setShowEditModal(false);
                setLoading(false);

                // Get Sessions
                JBrowseService.getSavedSessions({ user: currentUser }).then(
                    (response) => {
                        console.log("saved session response: ", response);
                        setCurrentSessions(response.result.reverse());
                        setLoading(false);
                    },
                    (error) => {
                        console.log("error: ", error);
                        setLoading(false);
                    }
                )

                // setSuccessEditMessage(true);
                // // Schedule to clear success message after 3 seconds
                // setTimeout(() => {
                //     setSuccessEditMessage(false);
                // }, 3000);
            },
            (error) => {
                console.log("error: ", error);
                setLoading(false);
                // setErrorEditMessage(true);
                // // Schedule to clear success message after 3 seconds
                // setTimeout(() => {
                //     setErrorEditMessage(false);
                // }, 3000);
            }
        );
    };

    const closeModal = () => {
        setShowEditModal(false);
    };

    useEffect(() => {
        if (selectedSession && selectedSession.location) {
            const locationParts = selectedSession.location.split(':');
            const chromosomePart = locationParts[0];
            const positions = locationParts[1].split('..');

            setChromosome(chromosomePart);
            setStart(positions[0]);
            setStop(positions[1]);
        } else {
            // Initialize with default values if selectedSession.location is not available
            setChromosome(chromosomes?.[0] || '');
            setStart('');
            setStop('');
        }
    }, [selectedSession, chromosomes]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            {selectedSession && (
                <div className={`fixed z-10 inset-0 overflow-y-auto flex justify-center`} style={{ zIndex: "1000" }}>
                    <div className="modal-dialog max-w-3xl w-full mx-2">
                        <div className="modal-content bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="modal-header p-4 flex justify-between items-center">
                                <h5 className="text-lg font-bold">Edit Session</h5>
                                <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>
                            </div>
                            <div className="modal-body p-4">
                                <Box
                                    component="form"
                                    sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        required
                                        id="name"
                                        label="Session Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    {chromosomes && chromosomes.length > 0 && (
                                        <TextField
                                            select
                                            label="Chromosome"
                                            value={chromosome}
                                            onChange={handleChromosomeChange}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            {chromosomes.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    <TextField
                                        required
                                        id="start"
                                        label="Start"
                                        name="start"
                                        type="number"
                                        value={start}
                                        onChange={(e) => setStart(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <TextField
                                        required
                                        id="stop"
                                        label="Stop"
                                        name="stop"
                                        type="number"
                                        value={stop}
                                        onChange={(e) => setStop(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Box>

                                {/* { successEditMessage.toString() } */}

                                {successEditMessage && (
                                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                        <strong className="font-bold">Success!</strong>
                                        <span className="block sm:inline"> Successfully updated a session.</span>
                                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                            <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={setSuccessEditMessage(false)}><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                        </span>
                                    </div>
                                )}

                                {errorEditMessage && (
                                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                        <strong className="font-bold">Error!</strong>
                                        <span className="block sm:inline"> There was an error updating a session, refresh and try again.</span>
                                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={setErrorEditMessage(false)}><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer p-4 flex justify-between items-center">
                                <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>
                                    Save Updates
                                </Button>
                                <button className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded" onClick={closeModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>
    );
}

export default EditSessionModal;


