import React, { useEffect, useState, useCallback, useRef } from 'react';
import ViewType from '@jbrowse/core/pluggableElementTypes/ViewType'
import Plugin from '@jbrowse/core/Plugin'
import PluginManager from '@jbrowse/core/PluginManager'
import InfoIcon from "@mui/icons-material/Info"
import createTracks from "./createTracks";
import createDefaultSession from './createDefaultSession';
import { createViewState, loadPlugins, JBrowseLinearGenomeView } from '@jbrowse/react-linear-genome-view'
import AccessVariantsModal from "./AccessVariantsModal";
import FeatureTableModal from './FeatureTableModal';
import HaploviewerModal from './HaploviewerModal';
import JBrowseService from '../services/jbrowse.service';
import BlastModal from './BlastModal';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import SequenceRetrievalModal from './SequenceRetrievalModal';
import SampleSequenceModal from './SampleSequenceModal';
import NotesModal from './NotesModal';
import CreateNotesModal from './CreateNotesModal'; 
import BatchNotesModal from './BatchNotesModal';
import EditNotesModal from './EditNotesModal';
import OpenAiModal from './OpenAiModal';
import AiFeatureModal from './AiFeatureModal';
import LiteratureModal from './LiteratureModal';
import ConversationModal from './ConversationModal';
import AlignmentModal from './AlignmentModal';
import { motion, AnimatePresence } from 'framer-motion';
import EventBus from "../common/EventBus";
import _, { result } from 'underscore';
import axios from 'axios';
import { autorun, toJS } from 'mobx';

// Chat Bot
import { Widget, addResponseMessage, toggleWidget, addLinkSnippet } from 'react-chat-widget';
import '../ChatBot.css';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from '@mui/icons-material/Search';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import CommentIcon from '@mui/icons-material/Comment';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;

export const CurrentSession = (props) => {
    let { assembly,
        tracks,
        setCurrentTracks,
        session,
        setCurrentSession,
        chromosome,
        selectedAssembly,
        selectedTracks,
        currentUser,
        selectedVariants,
        setSelectedVariants,
        accessVariantIsOpen,
        setAccessVariantIsOpen,
        blastModalIsOpen,
        setBlastModalIsOpen,
        blastData,
        setBlastData,
        loading,
        setLoading,
        sampleNames,
        setSampleNames,
        notesIsOpen,
        setNotesIsOpen,
        notes,
        setNotes,
        createTracks,
        createDefaultSession,
        setSelectedTracks,
        location,
        setLocation,
        state,
        setState,
        selectedCrop,
        setShowLiteratureSearch,
        toggleLiteratureSearch,
        setToggleLiteratureSearch,
        chromosomes,
        availableAssemblies,
        literatureModalIsOpen,
        setLiteratureModalIsOpen,
        setShowToolsModal,
    } = props;
    let [windowWidth, setWindowWidth] = useState(window.innerWidth * 0.98);
    let [sequence, setSequence] = useState("");
    let [gffs, setGffs] = useState([]);
    let [currentNote, setCurrentNote] = useState({});
    const [featureModalIsOpen, setFeatureModalIsOpen] = useState(false);
    const [haploModalIsOpen, setHaploModalIsOpen] = useState(false);
    const [sequenceModalIsOpen, setSequenceModalIsOpen] = useState(false);
    const [samplesSequenceIsOpen, setSampleSequenceIsOpen] = useState(false);
    const [createNotesModalIsOpen, setCreateNotesModalIsOpen] = useState(false);
    const [batchNotesModalIsOpen, setBatchNotesModalIsOpen] = useState(false)
    const [editNotesModalIsOpen, setEditNotesModalIsOpen] = useState(false);
    const [openAiModalIsOpen, setOpenAiModalIsOpen] = useState(false);
    const [aiFeatureModalIsOpen, setAiFeatureModalIsOpen] = useState(false);
    const [aiResponse, setAiResponse] = useState("");
    const [blastLoading, setBlastLoading] = useState(false);
    const [aiLoading, setAiLoading] = useState(false);
    const [blastParagraph, setBlastParagraph] = useState("");
    const [haploData, setHaploData] = useState({
        textArray: [],
        x: [],
        y: [],
        z: []
    });
    const [conversation, setConversation] = useState([]);
    const [literatureSearch, setLiteratureSearch] = useState([]);
    const [literatureSearchError, setLiteratureSearchError] = useState(false);
    const [literaturePrompt, setLiteraturePrompt] = useState("");
    const [isWidgetOpen, setIsWidgetOpen] = useState(false);
    const selectedTracksRef = useRef();
    const [isConversionModalOpen, setIsConversationModalOpen ] = useState(false);
    const [htmlBlob, setHtmlBlob ] = useState("");
    const [showAlignmentModal, setShowAlignmentModal ] = useState( false );
    const [sampleCodeError, setSampleCodeError  ] = useState(false);
    const [bamFileError, setBamFileError ] = useState( false );

    // console.log("current user: ", currentUser );

    // Chat Bot Logo
    const logo = require('../img/logo.png');

    class HighlightRegionPlugin extends Plugin {
        name = "HighlightRegionPlugin"

        install(pluginManager) {

            pluginManager.addToExtensionPoint(
                "Core-extendPluggableElement",
                pluggableElement => {
                    if (pluggableElement.name === "LinearGenomeView") {
                        const { stateModel } = pluggableElement
                        const newStateModel = stateModel.extend(self => {
                            const superRubberBandMenuItems = self.rubberBandMenuItems
                            return {
                                views: {
                                    rubberBandMenuItems() {
                                        return [
                                            ...superRubberBandMenuItems(),
                                            {
                                                label: "Access Variants",
                                                icon: SearchIcon,
                                                onClick: () => {
                                                    const { leftOffset, rightOffset } = self
                                                    const selectedRegions = self.getSelectedRegions(
                                                        leftOffset,
                                                        rightOffset
                                                    )
                                                    // console log the list of potentially multiple
                                                    // regions that were selected
                                                    setLoading(true);
                                                    // console.log("tracks: ", selectedTracksRef.current );
                                                    const vcfFiles = selectedTracksRef.current.filter(file => file.endsWith('.vcf'));
                                                    // console.log("vcf files: ", vcfFiles );

                                                    // const variantTracks = _.filter( tracks, obj => obj.type === 'VariantTrack');
                                                    // console.log("variant tracks: ", variantTracks);

                                                    var location = selectedRegions[0].refName + ":" + selectedRegions[0].start + "-" + selectedRegions[0].end;
                                                    // console.log("location: ", location );
                                                    setLocation(location);

                                                    var obj = {
                                                        starting: selectedRegions[0].start,
                                                        ending: selectedRegions[0].end,
                                                        chromosome: selectedRegions[0].refName,
                                                        vcfs: vcfFiles,
                                                        user: currentUser.email,
                                                        company: currentUser.DirectoryName,
                                                    };

                                                    // console.log('obj: ', obj);

                                                    // Send the obj to the backend using axios
                                                    JBrowseService.getVariants(obj).then(
                                                        (response) => {
                                                            console.log("varaints response: ", response);
                                                            // If no Variants were found...
                                                            if (isBlank(response.result)) {
                                                                console.log("show warning");
                                                                alert("no variants found.");
                                                                setLoading(false);
                                                                return;
                                                            }

                                                            var variants = response.result.split(',');
                                                            // console.log("variants: ", variants );
                                                            setSelectedVariants(variants);
                                                            setAccessVariantIsOpen(true)
                                                            setLoading(false);
                                                        },
                                                        (error) => {
                                                            console.log("error: ", error);
                                                            if (error.code === "ERR_NETWORK") {
                                                                console.warn("logout!!!");
                                                                EventBus.dispatch("logout");
                                                            }
                                                        }
                                                    )

                                                }
                                            },
                                            {
                                                label: "Blast Region",
                                                icon: BlurCircularIcon,
                                                onClick: () => {
                                                    const { leftOffset, rightOffset } = self
                                                    const selectedRegions = self.getSelectedRegions(leftOffset, rightOffset)
                                                    // console log the list of potentially multiple
                                                    // regions that were selected
                                                    // console.log("region: ", selectedRegions);
                                                    setBlastLoading(true);
                                                    setLoading(true);
                                                    setBlastParagraph("");

                                                    var obj = {
                                                        starting: selectedRegions[0].start,
                                                        ending: selectedRegions[0].end,
                                                        chromosome: selectedRegions[0].refName,
                                                        genome: assembly.name + ".fa",
                                                        crop: selectedCrop,
                                                        user: currentUser.username,
                                                        company: currentUser.DirectoryName
                                                    };
                                                    console.log("obj: ", obj);

                                                    async function handleBlastRequest() {
                                                        try {
                                                            const response = await JBrowseService.blastRegion(obj);
                                                            console.log("blast response: ", response);
                                                            // console.log("got here: ", selectedTracksRef.current );
                                                            /** Add Blasts Tracks */
                                                            var tracks = [...new Set([...selectedTracksRef.current, ...response.files])];
                                                            // console.log("tracks before: ", tracks );
                                                            setSelectedTracks(tracks);

                                                            // Since createTracks is async, we await its result
                                                            var newTracks = await createTracks(selectedAssembly, tracks);
                                                            // console.log("new tracks: ", newTracks );

                                                            // Clean Up, old BlastGff files will mess this up
                                                            const cleanTracks = newTracks.filter(item => item !== undefined);
                                                            var trackArray = _.pluck(cleanTracks, 'name');
                                                            setCurrentTracks(newTracks);

                                                            const newSession = createDefaultSession(selectedAssembly, trackArray);
                                                            setCurrentSession(newSession);

                                                            // Introducing a slight delay to ensure all updates are processed
                                                            setTimeout(() => {
                                                                updateTracks(cleanTracks, newSession);
                                                                setBlastData(response.result);
                                                                setBlastModalIsOpen(true);
                                                                setLoading(false);
                                                                setBlastLoading(false);
                                                            }, 250);
                                                        } catch (error) {
                                                            console.log('blast error: ', error);
                                                            setLoading(false);
                                                            setBlastLoading(false);
                                                            if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_REQUEST") {
                                                                console.warn("logout!!!");
                                                                EventBus.dispatch("logout");
                                                            }
                                                        }
                                                    }

                                                    // To invoke the function:
                                                    handleBlastRequest();

                                                }
                                            },
                                            {
                                                label: "Create Note",
                                                icon: CommentIcon,
                                                onClick: () => {
                                                    const { leftOffset, rightOffset } = self
                                                    const selectedRegions = self.getSelectedRegions(
                                                        leftOffset,
                                                        rightOffset
                                                    )
                                                    // console log the list of potentially multiple
                                                    // regions that were selected
                                                    console.log("region: ", selectedRegions);
                                                    var location = selectedRegions[0].refName + ":" + selectedRegions[0].start + "-" + selectedRegions[0].end;
                                                    // console.log("location: ", location );
                                                    setLocation(location);
                                                    setCreateNotesModalIsOpen(true);
                                                }
                                            }
                                        ]
                                    }
                                }
                            }
                        })

                        pluggableElement.stateModel = newStateModel
                    }
                    return pluggableElement
                }
            )
        }

        configure() { }
    }

    class VariantFeaturePlugin extends Plugin {
        name = "VariantFeaturePlugin";

        install(pluginManager) {
            pluginManager.addToExtensionPoint(
                "Core-extendPluggableElement",
                pluggableElement => {
                    // console.log("pluggable el: ", pluggableElement.name );
                    if (pluggableElement.name === "LinearVariantDisplay") {
                        const { stateModel } = pluggableElement;
                        const newStateModel = stateModel.extend(self => {
                            const superContextMenuItems = self.contextMenuItems;
                            return {
                                views: {
                                    contextMenuItems() {
                                        const feature = self.contextMenuFeature;
                                        if (!feature) {
                                            return superContextMenuItems();
                                        }
                                        const newMenuItems = [
                                            ...superContextMenuItems(),
                                            {
                                                label: "Haplotype Score",
                                                icon: SportsScoreIcon,
                                                onClick: () => {
                                                    setLoading( true );
                                                    // Get Track Name
                                                    let file_long = self.$treenode._initialSnapshot.configuration;
                                                    let file = file_long.replace(/-LinearVariantDisplay$/, '');
                                                    let track = _.filter(selectedTracks, x => x.trackId === file);
                                                    // console.log('track: ', track[0].trackId );
                                                    let loc = feature.data.refName + ":" + feature.data.start + "-" + feature.data.end;
                                                    // console.log("location: ", loc);
                                                    const vcfFiles = selectedTracksRef.current.filter(file => file.endsWith('.vcf'));
                                                    const gffFiles = selectedTracksRef.current.filter(file => file.endsWith('.gff'));
                                                    // Format GFF's for Bams
                                                    const cleanGffs = removeFilesWithSuffix( gffFiles, "notes.gff")
                                                    const removeSuffixGffs = removeFileExtensions( cleanGffs );
                                                    const bamGffs = extractNameBeforeUnderscore( removeSuffixGffs );
                                                    
                                                    let obj = {
                                                        vcf: vcfFiles[0],
                                                        loc: loc,
                                                        assembly: selectedAssembly,
                                                        gffs: cleanGffs,
                                                        company: currentUser.DirectoryName,
                                                        user: currentUser.email,
                                                    };
                                                    console.log("obj: ", obj);

                                                    // setLoading(false);
                                                    // return;

                                                    // Send request
                                                    JBrowseService.getHaplotypeScore(obj).then(
                                                        (response) => {
                                                            console.log("haploScore response: ", response);
                                                            if( response.status === 0 ) {
                                                                // Success
                                                                setLoading( false );
                                                            } else if( response.status === 2 ) {
                                                                // No Bams Found
                                                                setLoading( false );
                                                            }
                                                        },
                                                        (error) => {
                                                            console.log("error: ", error);
                                                            setLoading( false );
                                                        }
                                                    )
                                                }
                                            },
                                            {
                                                label: "Create Note",
                                                icon: CommentIcon,
                                                onClick: () => {
                                                    let loc = feature.data.refName + ":" + feature.data.start + "-" + feature.data.end;
                                                    console.log("location: ", loc);
                                                    setLocation(loc);
                                                    setCreateNotesModalIsOpen(true);
                                                }
                                            },
                                            {
                                                label: "AI Analysis",
                                                icon: PersonSearchIcon,
                                                onClick: () => {
                                                    setLoading(true);
                                                    console.log("feature: ", feature);
                                                    let obj = {
                                                        user: currentUser.email,
                                                        feature: JSON.stringify(Object.assign({}, feature.data, feature.variant))
                                                    };
                                                    // console.log("obj: ", JSON.stringify(obj.feature) );
                                                    // return;
                                                    // Send Request
                                                    JBrowseService.aiFeaturePrompt(obj).then(
                                                        (response) => {
                                                            console.log('ai response: ', response);
                                                            setAiResponse(response.result);
                                                            setAiFeatureModalIsOpen(true);
                                                            setLoading(false)
                                                            setShowLiteratureSearch(true);
                                                            setToggleLiteratureSearch(false);
                                                        },
                                                        (error) => {
                                                            console.log("ai error: ", error);
                                                            setLoading(false)
                                                        }
                                                    )
                                                }
                                            }
                                        ];

                                        return newMenuItems;
                                    }
                                }
                            };
                        });

                        pluggableElement.stateModel = newStateModel;
                    }
                    return pluggableElement;
                }
            );
        }

        configure() { }
    }

    class GffFeaturePlugin extends Plugin {
        name = "GffFeaturePlugin";

        install(pluginManager) {
            pluginManager.addToExtensionPoint(
                "Core-extendPluggableElement",
                pluggableElement => {
                    // console.log("pluggable el: ", pluggableElement.name );
                    if (pluggableElement.name === "LinearBasicDisplay") {
                        const { stateModel } = pluggableElement;
                        const newStateModel = stateModel.extend(self => {
                            const superContextMenuItems = self.contextMenuItems;
                            return {
                                views: {
                                    contextMenuItems() {
                                        const feature = self.contextMenuFeature;
                                        if (!feature) {
                                            return superContextMenuItems();
                                        }
                                        const newMenuItems = [
                                            ...superContextMenuItems(),
                                            {
                                                label: "Alignment Viewer",
                                                icon: VisibilityIcon,
                                                onClick: () => {
                                                    console.log("Feature: ", feature);
                                                    setLoading(true)
                                                    // console.log("Track: ", track );
                                                    // Get Track Name
                                                    let file_long = self.$treenode._initialSnapshot.configuration;
                                                    let track = file_long.replace(/-LinearBasicDisplay$/, '');
                                                    let fixed_track = track.replace(/_\d+$/, '');
                                                    // console.log("track: ", track );
                                                  
                                                    let Location = feature.data.refName+":"+feature.data.start+"-"+feature.data.end;

                                                    const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));
                                                    var obj = {
                                                        user: currentUser.email,
                                                        company: currentUser.DirectoryName,
                                                        assembly: assembly.name,
                                                        vcf: vcfFiles[0],
                                                        location: Location,
                                                        externalID: fixed_track,
                                                    };
                                                    console.log("obj: ", obj );
                                                    // Send Request
                                                    JBrowseService.alignmentViewer( obj ).then(
                                                        (response) => {
                                                            console.log("av response: ", response );
                                                            switch (response.status) {
                                                                case 1:
                                                                    console.log('SampleCode Error.');
                                                                    setSampleCodeError( true );
                                                                    setLoading( false )
                                                                    setTimeout( () => {
                                                                        setSampleCodeError( false );
                                                                    },5000);
                                                                    break;
                                                                case 2:
                                                                    console.log('Bam file Error.')
                                                                    setBamFileError( true );
                                                                    setLoading( false )
                                                                    setTimeout( () => {
                                                                        setBamFileError( false );
                                                                    },5000);
                                                                    break
                                                                case 0:
                                                                    console.log('Success.');
                                                                    setHtmlBlob( response.result );
                                                                    setShowAlignmentModal( true );
                                                                    setLoading( false )
                                                                    break;
                                                            }
                                                        }, 
                                                        ( error ) => {
                                                            console.log("error: ", error );
                                                            setLoading(false)
                                                        }
                                                    )

                                                }
                                            },
                                            {
                                                label: "Create Note",
                                                icon: CommentIcon,
                                                onClick: () => {
                                                    let loc = feature.data.refName + ":" + feature.data.start + "-" + feature.data.end;
                                                    console.log("location: ", loc);
                                                    setLocation(loc);
                                                    setCreateNotesModalIsOpen(true);
                                                }
                                            },
                                            {
                                                label: "AI Analysis",
                                                icon: PersonSearchIcon,
                                                onClick: () => {
                                                    console.log("feature: ", feature );
                                                    let goTerms = feature.data.subfeatures[0].ontology_term;
                                                    console.log("goTerms: ", goTerms );
                                                    setLoading(true);

                                                    let Location = feature.data.refName+":"+feature.data.start+"-"+feature.data.end;
                                                    const gffFiles = selectedTracks.filter(file => file.endsWith('.gff'));
                                                    const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));
                                                    const gffs = gff3Files.concat( gffFiles );

                                                    var obj = {
                                                        location: Location,
                                                        crop: selectedCrop,
                                                        assembly: selectedAssembly,
                                                        goTerms: goTerms || [],
                                                        gff: gffs[0],
                                                        user: currentUser.email,
                                                        company: currentUser.DirectoryName
                                                    }
                                                    console.log('obj: ', obj );
                                                    console.log("send prompt..");
                                                    // Send Request
                                                    JBrowseService.gffClickPrompt( obj ).then(
                                                        (response) => {
                                                            console.log('openAi response: ', response );
                                                            setLoading(false);
                                            
                                                            toggleWidget();
                                                            let prompt = "Describe the primary biological function of a gene using the provided list of Gene Ontology (GO) terms for a specific crop: "+selectedCrop+" and assembly:"+selectedAssembly+". Focus on detailing the specific interactions and roles of the gene based on these GO terms. The description should be concise and strictly adhere to the functions indicated by the GO terms without implying the gene's importance or broader biological significance. Use only the most relevant GO terms and discard terms that are generic, ambiguous, or not relevant to the primary biological function. Your analysis should leverage the depth of information in the Go Terms to provide advanced interpretations suitable for an audience well-versed in bioinformatics. The description should be no longer than one short paragraph. The GO terms are: "+response.goTerms
                                                            let gff_prmopt = "Given these lines from a gff file from crop: Tomato, and Assembley: S_lycopersicum_chromosomes.3.00 go through the 'Attributes' tab and find interesting facts about this region. Your Response should refraim from using generic bioinformatic terms and instead tailor your insights to an advanced scientific audience with a solid understanding of bioinformatics, eschewing basic explanations in favor of more profound, nuanced interpretations that demonstrate a deep comprehension of the subject matter. gff lines: { "+response.gffs+" }"
                                                            let convo = [
                                                                {
                                                                    role: "system",
                                                                    content: "You're role is a bioinformatics specalist and you task is to give detail about the agricultural data provided, be precise and concise."
                                                                },
                                                                {
                                                                    role: 'user',
                                                                    content: prompt+"\n"+gff_prmopt
                                                                },
                                                                {
                                                                    role: "assistant",
                                                                    content: response.result
                                                                }
                                                            ];
                                                            setConversation( convo );
                                                            addResponseMessage(response.result)
                                                            setLoading(false);
                                                            setAccessVariantIsOpen( false );
                                                            setShowLiteratureSearch( true );
                                                            setToggleLiteratureSearch( false );
                                                        },
                                                        (error) => {
                                                            console.log("error: ", error );
                                                            console.log("message: ", error.response.data );
                                                            addResponseMessage("openai error, try again later.");
                                                            setTimeout(()=> {
                                                                addResponseMessage("");
                                                            },3000);
                                                            setLoading(false);
                                                        }
                                                    )
                                                
                                                    // const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));
                                                    // let obj = {
                                                    //     user: currentUser.email,
                                                    //     company: currentUser.DirectoryName,
                                                    //     gff: gff3Files[0],
                                                    //     crop: selectedCrop,
                                                    //     chromosome: feature.data.refName,
                                                    //     start: feature.data.start,
                                                    //     stop: feature.data.end,
                                                    // };
                                                    // console.log('obj: ', obj );
                                                    // // Chat Bot  -- Select the button after the component and widget have mounted
                                                    // let response = `Welcome to the Verinomics GFF GPT!`
                                                    // addResponseMessage(response);
                                                    // // return;
                                                    // // Send Request
                                                    // JBrowseService.aiFeaturePrompt(obj).then(
                                                    //     (response) => {
                                                    //         console.log('ai response: ', response);
                                                    //         if( response.status === 0 ) {
                                                    //             // Success
                                                    //             // Open ChatBot
                                                    //             toggleWidget();
                                                    //             setIsWidgetOpen(true);
                                                    //             // Set Conversation
                                                    //             let convo = [
                                                    //                 {
                                                    //                     role: "system",
                                                    //                     content: "Be precise and concise."
                                                    //                 },
                                                    //                 {
                                                    //                     role: 'user',
                                                    //                     content: response.result.prompt
                                                    //                 }
                                                    //             ];
                                                    //             setConversation(convo);
                                                    //             // Send open.ai Response
                                                    //             // const aiMessageContent = response.result.conversation_response[0].openai_response;
                                                    //             const aiMessageContent = response.result.openAi;
                                                    //             addResponseMessage(aiMessageContent);
                                                    //             // Update Conversation 
                                                    //             let newMessage = {
                                                    //                 role: 'assistant',
                                                    //                 content: aiMessageContent
                                                    //             }
                                                    //             setConversation(currentConversation => [...currentConversation, newMessage]);
                                                    //             console.log("converstaion: ", conversation);
                                                    //             setLoading(false);
                                                    //         } else {
                                                    //             // Error
                                                    //             setLoading(false);
                                                    //             addResponseMessage("openai error, try again later.");
                                                    //         }
                                                    //     },
                                                    //     (error) => {
                                                    //         console.log("ai error: ", error);
                                                    //         setLoading(false)
                                                    //     }
                                                    // )
                                                }
                                            }
                                        ];

                                        return newMenuItems;
                                    }
                                }
                            };
                        });

                        pluggableElement.stateModel = newStateModel;
                    }
                    return pluggableElement;
                }
            );
        }

        configure() { }

       
    }

    class ColorPlugin {
        install() { }
        configure(pluginManager) {
            pluginManager.jexl.addFunction('colorFeature', (feature) => {
                // console.log("feature: ", feature );
                // console.log("feature: ",JSON.stringify(feature, null, 2));
                let type = feature.get('type').trim();
                // console.log("type: ", type);
                switch (type) {
                    case 'NonReference':
                        return 'blue';
                    case 'Reference':
                        return 'green';
                    case 'Heterozygous':
                        return '#ffd966';
                    case 'Missing':
                        return 'grey';
                    default:
                        return '#ff6b6b'; // Default color for types not listed ( coral )
                }
            });
        }
    }

    const navigateToLocation = useCallback((location) => {
        if (!state) return;

        const s = createViewState({
            assembly: assembly,
            tracks: tracks,
            plugins: [HighlightRegionPlugin, VariantFeaturePlugin, GffFeaturePlugin, ColorPlugin],
            configuration: {
                logoPath: {
                    uri: "img/logo.svg"
                },
                theme: {
                    palette: {
                        primary: {
                            main: "#4a90e2", // A crisp, engaging blue for primary actions and emphasis
                        },
                        secondary: {
                            main: "#0f4d92", // A light purple for secondary actions and highlights
                        },
                        tertiary: {
                            main: "#7b8dff", // A soft, approachable violet for tertiary accents and features
                        },
                        quaternary: {
                            main: "#ff6b6b", // A bright, energetic yellow for attention-grabbing elements and alerts
                        }
                    }
                },
                hierarchical: {
                    sort: {
                        trackNames: true,
                        categories: true
                      },
                      defaultCollapsed: {
                        categoryNames: ["FeatureTrack","VariantTrack" ], // only collapse some categories on initial startup
                        topLevelCategories: true, // collapse all top level categories on initial startup
                        subCategories: true // collapse all subcategories on initial startup
                      }
                }
            },
            location: location,
            defaultSession: session
        })
        // console.log("state: ", s);

        // Set State
        setState(s);
    }, [state, assembly, tracks, session]); // Add other dependencies if needed

    const updateTracks = useCallback((newTracks, newSession) => {
        // if (!state) {
        //     console.warn("State is not initialized yet.");
        //     return;
        // }
        // Init
        // setState(null);
        // console.log("updating new tracks: ", newTracks);
        // console.log("chromosome: ", chromosome + ":1..100,000");

        const s = createViewState({
            assembly: assembly,
            tracks: newTracks, // Use the new tracks passed to the function
            plugins: [HighlightRegionPlugin, VariantFeaturePlugin, GffFeaturePlugin, ColorPlugin],
            configuration: {
                logoPath: {
                    uri: "img/logo.svg"
                },
                theme: {
                    palette: {
                        primary: {
                            main: "#4a90e2", // A crisp, engaging blue for primary actions and emphasis
                        },
                        secondary: {
                            main: "#0f4d92", // A light purple for secondary actions and highlights
                        },
                        tertiary: {
                            main: "#7b8dff", // A soft, approachable violet for tertiary accents and features
                        },
                        quaternary: {
                            main: "#ff6b6b", // A bright, energetic yellow for attention-grabbing elements and alerts
                        }
                    }
                },
                hierarchical: {
                    sort: {
                        trackNames: true,
                        categories: true
                      },
                      defaultCollapsed: {
                        categoryNames: ["FeatureTrack","VariantTrack" ], // only collapse some categories on initial startup
                        topLevelCategories: true, // collapse all top level categories on initial startup
                        subCategories: true // collapse all subcategories on initial startup
                      }
                }
            },
            location: chromosome + ":1..100,000",
            defaultSession: newSession
        });

        // console.log("Updated state: ", s);

        // Set State
        setState(s);
    }, [assembly]); // Added useCallback for more controlled updates

    useEffect(() => {
        if (tracks && assembly && session) {
            // console.log("tracks: ", tracks);
            // console.log("session: ", session );
            
            // const run = async () => {
            //     const plugins = await loadPlugins([
            //         {
            //             name: 'MultilevelLinearView',
            //             url: 'https://unpkg.com/jbrowse-plugin-multilevel-linear-view/dist/jbrowse-plugin-multilevel-linear-view.umd.production.min.js'
            //         },
            //         {
            //             name: "Quantseq",
            //             url: "https://unpkg.com/jbrowse-plugin-quantseq/dist/jbrowse-plugin-quantseq.umd.production.min.js"
            //         }
            //         // You can include more plugins here
            //     ])
                const s = createViewState({
                    assembly: assembly,
                    tracks: tracks,
                    plugins: [HighlightRegionPlugin, VariantFeaturePlugin, GffFeaturePlugin, ColorPlugin],
                    // plugins: plugins.map(p => p.plugin),
                    configuration: {
                        logoPath: {
                            uri: "img/logo.svg"
                        },
                        theme: {
                            palette: {
                                primary: {
                                    main: "#4a90e2", // A crisp, engaging blue for primary actions and emphasis
                                },
                                secondary: {
                                    main: "#0f4d92", // A light purple for secondary actions and highlights
                                },
                                tertiary: {
                                    main: "#7b8dff", // A soft, approachable violet for tertiary accents and features
                                },
                                quaternary: {
                                    main: "#ff6b6b", // A bright, energetic yellow for attention-grabbing elements and alerts
                                }
                            }
                        },
                        hierarchical: {
                            sort: {
                                trackNames: true,
                                categories: true
                            },
                            defaultCollapsed: {
                                categoryNames: ["FeatureTrack","VariantTrack" ], // only collapse some categories on initial startup
                                topLevelCategories: true, // collapse all top level categories on initial startup
                                subCategories: true // collapse all subcategories on initial startup
                            }
                        }
                    },
                    location: chromosome + ":1..100,000",
                    defaultSession: session
                })
                // console.log("state: ", s);

                // Set State
                setState(s);
            // }
            // run();

            setTimeout(() => {
                setLoading(false);
            }, 1000);

        }
    }, [tracks, assembly, session]);
    
    useEffect(() => {
        if (selectedTracks) {
            selectedTracksRef.current = selectedTracks;
            // console.log("got here w/ sel tracks: ", selectedTracks );

            // console.log('selected tracks: ', selectedTracks );
            const gffFiles = selectedTracks.filter(file => file.endsWith('.gff'));
            const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));
            const gffs = gffFiles.concat(gff3Files);
            var formatted = _.map(gffs, value => {
                return {
                    fileName: value
                };
            });
            // console.log("f gffs: ", formatted );
            setGffs(formatted);
        }
    }, [selectedTracks])

    useEffect(() => {
        if (notes.length > 0) {
            const file = process.env.PUBLIC_URL + "/JBrowseNotes/Genomes/" + selectedAssembly + "/" + currentUser.DirectoryName + "/" + currentUser.DirectoryName + ".notes.gff";
            console.log("file: ", file);

            const checkAndAddNotesFile = async (file) => {
                try {
                    await axios.get(API_URL + file, {
                        maxContentLength: 100,
                        headers: {
                            'Cache-Control': 'no-cache', // Optionally add this line to set cache-control headers
                            'Pragma': 'no-cache' // For older HTTP/1.0 proxies and servers
                        }
                    });
                    const normalizedFile = file.startsWith('/') ? file.substring(1) : file;
                    console.log("Notes File exists: ", normalizedFile);

                    if (!selectedTracks.includes(normalizedFile)) {
                        const tracks = selectedTracks.concat(normalizedFile);
                        // console.log("selected tracks, from notes: ", tracks);
                        setSelectedTracks(tracks);

                        const myTracks = await createTracks(selectedAssembly, tracks);
                        const cleanTracks = myTracks.filter(item => item !== undefined);
                        const trackArray = _.pluck(cleanTracks, 'name');
                        setCurrentTracks(cleanTracks);

                        const newSession = createDefaultSession(selectedAssembly, trackArray);
                        setCurrentSession(newSession);

                        setTimeout(() => {
                            updateTracks(cleanTracks, newSession);
                            setLoading(false);
                        }, 100);
                    } else {
                        console.log("do nothing!");
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        console.log("Notes file does not exist");
                    } else {
                        console.log("An error occurred while fetching notes file:", error);
                    }
                }
            };

            checkAndAddNotesFile(file);
        }
    }, [notes]);

    useEffect(() => {
        if (state) {
            // Assuming you have access to the JBrowse session/view in this component
            const currentView = state.session.views[0];

            // Set up an autorun to observe changes
            const disposer = autorun(() => {
                const locationObject = toJS(currentView.coarseDynamicBlocks);
                // console.log("details: ", locationObject );
                if (locationObject.length > 0) {
                    let location = locationObject[0].refName + ":" + Math.floor(locationObject[0].start) + ".." + Math.floor(locationObject[0].end);
                    // console.log("loc: ", location);
                    setLocation(location);
                }
            });

            // Cleanup the autorun when the component is unmounted
            return () => {
                disposer();
            };
        }
    }, [state]);

    useEffect(() => {
        setToggleLiteratureSearch(false);
        // Handler to call on window resize
        function handleResize() {
            setWindowWidth(window.innerWidth * 0.98);
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCloseAlert = () => {
        setBlastLoading(false); // Change this to your actual state update logic
        setLiteratureSearchError(false);
    };

    const handleNewUserMessage = async (newMessage) => {
        setLoading(true);
        console.log(`New message incoming! ${newMessage}`);
        let userMessage = {
            role: 'user',
            content: newMessage
        };

        // Update Conversation and send to backend in one step
        setConversation(currentConversation => {
            const updatedConversation = [...currentConversation, userMessage];

            let obj = {
                user: currentUser.email,
                conversation: updatedConversation
            };
            console.log("obj: ", obj);
            // Call the backend service
            JBrowseService.aiConversation(obj).then(
                (response) => {
                    console.log('ai response: ', response);
                    // Process the response...
                    // Assuming you are handling the response here
                    const aiMessageContent = response.result.conversation_response[0].openai_response;
                    addResponseMessage(aiMessageContent);

                    // Update Conversation with assistant's response
                    setConversation(currentConv => [...currentConv, {
                        role: 'assistant',
                        content: aiMessageContent
                    }]);
                    setShowLiteratureSearch(true);
                    setLoading(false);

                },
                (error) => {
                    console.error("ai error: ", error);
                    setLoading(false);
                }
            );

            // Return the updated conversation to update the state
            return updatedConversation;
        });
    };

    return (
        <div>

            {blastLoading && (
                <div className="bg-white border-l-4 border-green-600 text-green-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    {/* <SyncIcon style={{marginRight: "5px"}}/> */}
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <div>
                        <strong className="block">Processing Blast</strong>
                        We're currently analyzing the selected region and generating its corresponding blast track. Hang tight, it won't take long!
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {literatureSearchError && (
                <div className="bg-white border-l-4 border-yellow-600 text-yellow-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    {/* <SyncIcon style={{marginRight: "5px"}}/> */}
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <div>
                        <strong className="block">Literature Search</strong>
                        We didn't find any articles with that query, try again.
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {sampleCodeError && (
                <div className="bg-white border-l-4 border-yellow-600 text-yellow-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    <InfoIcon style={{marginRight: "5px"}}/>
                    <div>
                        <strong className="block">SampleCode Error</strong>
                        We didn't find that sample in our database. try a sample track (.gff)
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {bamFileError && (
                <div className="bg-white border-l-4 border-yellow-600 text-yellow-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    <InfoIcon style={{marginRight: "5px"}}/>
                    <div>
                        <strong className="block">Bam File Error</strong>
                        We didn't find that bam file on our server. 
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {/* JBrowse 2 */}
            <AnimatePresence>
                {state && (
                    <>
                        <motion.div
                            initial={{ opacity: 0, x: '100%' }} // Start from the right
                            animate={{ opacity: 1, x: '0%' }}   // Move to its original position
                            exit={{ opacity: 0, x: '100%' }}    // Exit to the right
                            transition={{ duration: 0.5 }}
                            className="bg-gray-300"
                        >
                            <div style={{ width: windowWidth + "px" }} id="jbrowse2">
                                <JBrowseLinearGenomeView viewState={state} />
                            </div>
                        </motion.div>
                        <Widget
                            handleNewUserMessage={handleNewUserMessage}
                            profileAvatar={logo}
                            title="Verinomics GFF GPT"
                            subtitle="powered by openai"
                            resizable={false}
                            emojis={false}
                            showBadge={false}
                            showCloseButton={true}
                        />
                    </>
                )}
            </AnimatePresence >

            {accessVariantIsOpen && (
                <AccessVariantsModal
                    selectedVariants={selectedVariants}
                    setSelectedVariants={setSelectedVariants}
                    selectedAssembly={selectedAssembly}
                    selectedTracks={selectedTracks}
                    location={location}
                    currentUser={currentUser}
                    sequence={sequence}
                    setSequence={setSequence}
                    setSequenceModalIsOpen={setSequenceModalIsOpen}
                    featureModalIsOpen={featureModalIsOpen}
                    setFeatureModalIsOpen={setFeatureModalIsOpen}
                    accessVariantIsOpen={accessVariantIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    haploData={haploData}
                    setHaploData={setHaploData}
                    setHaploModalIsOpen={setHaploModalIsOpen}
                    setLoading={setLoading}
                    sampleNames={sampleNames}
                    setSampleNames={setSampleNames}
                    setSampleSequenceIsOpen={setSampleSequenceIsOpen}
                    setOpenAiModalIsOpen={setOpenAiModalIsOpen}
                    selectedCrop={selectedCrop}
                    setConversation={setConversation}
                    setShowLiteratureSearch={setShowLiteratureSearch}
                    setToggleLiteratureSearch={setToggleLiteratureSearch}
                    chromosomes={chromosomes}
                    availableAssemblies={availableAssemblies}
                    currentAssembly={assembly}
                />
            )}

            {featureModalIsOpen && (
                <FeatureTableModal
                    gffs={gffs}
                    featureModalIsOpen={featureModalIsOpen}
                    setFeatureModalIsOpen={setFeatureModalIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    navigateToLocation={navigateToLocation}
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    selectedCrop={selectedCrop}
                    aiLoading={aiLoading}
                    setAiLoading={setAiLoading}
                />
            )}

            {haploModalIsOpen && (
                <HaploviewerModal
                    haploData={haploData}
                    setHaploData={setHaploData}
                    haploModalIsOpen={haploModalIsOpen}
                    setHaploModalIsOpen={setHaploModalIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    setLoading={setLoading}
                />
            )}

            {blastModalIsOpen && (
                <BlastModal
                    blastData={blastData}
                    setBlastData={setBlastData}
                    blastModalIsOpen={blastModalIsOpen}
                    setBlastModalIsOpen={setBlastModalIsOpen}
                    navigateToLocation={navigateToLocation}
                    setLoading={setLoading}
                    blastLoading={blastLoading}
                    setBlastLoading={setBlastLoading}
                    blastParagraph={blastParagraph}
                    setBlastParagraph={setBlastParagraph}
                    currentUser={currentUser}
                    aiLoading={aiLoading}
                    setAiLoading={setAiLoading}
                    chromosomes={chromosomes}
                    location={location}
                    currentAssembly={selectedAssembly}
                    selectedCrop={selectedCrop}
                    selectedTracksRef={selectedTracksRef}
                    setSelectedTracks={setSelectedTracks}
                    setCurrentTracks={setCurrentTracks}
                    setCurrentSession={setCurrentSession}
                    updateTracks={updateTracks}
                />
            )}

            {sequenceModalIsOpen && (
                <SequenceRetrievalModal
                    sequence={sequence}
                    setSequenceModalIsOpen={setSequenceModalIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    location={location}
                />
            )}

            {samplesSequenceIsOpen && (
                <SampleSequenceModal
                    sampleNames={sampleNames}
                    samplesSequenceIsOpen={samplesSequenceIsOpen}
                    setSampleSequenceIsOpen={setSampleSequenceIsOpen}
                    setLoading={setLoading}
                    selectedAssembly={selectedAssembly}
                    selectedTracks={selectedTracks}
                    location={location}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    currentUser={currentUser}
                />
            )}

            {notesIsOpen && (
                <NotesModal
                    notes={notes}
                    notesIsOpen={notesIsOpen}
                    setNotesIsOpen={setNotesIsOpen}
                    navigateToLocation={navigateToLocation}
                    setLoading={setLoading}
                    setBatchNotesModalIsOpen={setBatchNotesModalIsOpen}
                    selectedAssembly={selectedAssembly}
                    currentUser={currentUser}
                    setCurrentNote={setCurrentNote}
                    setEditNotesModalIsOpen={setEditNotesModalIsOpen}
                    setNotes={setNotes}
                />
            )}

            {createNotesModalIsOpen && (
                <CreateNotesModal
                    createNotesModalIsOpen={createNotesModalIsOpen}
                    setCreateNotesModalIsOpen={setCreateNotesModalIsOpen}
                    location={location}
                    setLocation={setLocation}
                    currentUser={currentUser}
                    selectedAssembly={selectedAssembly}
                    setLoading={setLoading}
                    createTracks={createTracks}
                    selectedTracks={selectedTracks}
                    setCurrentTracks={setCurrentTracks}
                    setCurrentSession={setCurrentSession}
                    createDefaultSession={createDefaultSession}
                    updateTracks={updateTracks}
                    setNotes={setNotes}
                />
            )}

            {batchNotesModalIsOpen && (
                <BatchNotesModal
                    batchNotesModalIsOpen={batchNotesModalIsOpen}
                    setBatchNotesModalIsOpen={setBatchNotesModalIsOpen}
                    currentUser={currentUser}
                    selectedAssembly={selectedAssembly}
                    setLoading={setLoading}
                    setNotesIsOpen={setNotesIsOpen}
                    setNotes={setNotes}
                />
            )}

            {editNotesModalIsOpen && (
                <EditNotesModal
                    currentNote={currentNote}
                    editNotesModalIsOpen={editNotesModalIsOpen}
                    setEditNotesModalIsOpen={setEditNotesModalIsOpen}
                    selectedAssembly={selectedAssembly}
                    currentUser={currentUser}
                    setNotesIsOpen={setNotesIsOpen}
                    setLoading={setLoading}
                    setNotes={setNotes}
                />
            )}

            {openAiModalIsOpen && (
                <OpenAiModal
                    setOpenAiModalIsOpen={setOpenAiModalIsOpen}
                    openAiModalIsOpen={openAiModalIsOpen}
                    gffs={gffs}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    navigateToLocation={navigateToLocation}
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    selectedCrop={selectedCrop}
                    aiLoading={aiLoading}
                    setAiLoading={setAiLoading}
                    selectedAssembly={selectedAssembly}
                    selectedTracks={selectedTracks}
                    setConversation={setConversation}
                />
            )}

            {aiFeatureModalIsOpen && (
                <AiFeatureModal
                    aiFeatureModalIsOpen={aiFeatureModalIsOpen}
                    setAiFeatureModalIsOpen={setAiFeatureModalIsOpen}
                    aiResponse={aiResponse}
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                />
            )}

            {literatureModalIsOpen && (
                <LiteratureModal
                    literatureSearch={literatureSearch}
                    setLiteratureSearch={setLiteratureSearch}
                    literatureModalIsOpen={literatureModalIsOpen}
                    setLiteratureModalIsOpen={setLiteratureModalIsOpen}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    literaturePrompt={literaturePrompt}
                    setLiteraturePrompt={setLiteraturePrompt}
                    selectedCrop={selectedCrop}
                    conversation={conversation}
                    isWidgetOpen={isWidgetOpen}
                    setLiteratureSearchError={setLiteratureSearchError}
                    setShowToolsModal={setShowToolsModal}
                />
            )}

            { isConversionModalOpen && (
                <ConversationModal
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    setIsConversationModalOpen={setIsConversationModalOpen}
                />
            )}

            { showAlignmentModal && (
                <AlignmentModal 
                    htmlBlob={htmlBlob}
                    show={showAlignmentModal}
                    setShow={ setShowAlignmentModal }
                />
            )}

        </div>
    )
}

function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

function findLastAssistantContent(array) {
    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].role === "assistant") {
            return array[i].content;
        }
    }
    return null; // Return null or any other value if not found
}

function removeFileExtensions(files) {
    return files.map(file => {
        // Remove the extension from each file name
        return file.replace(/\.[^/.]+$/, "");
    });
}

function removeFilesWithSuffix(files, suffix) {
    // Use filter to create a new array excluding files that end with the given suffix
    return files.filter(file => !file.endsWith(suffix));
}

function extractNameBeforeUnderscore(strings) {
    return strings.map(str => {
        // Split the string by the underscore and return the first part
        const [name,] = str.split('_');
        return name;
    });
}

function addSuffixBeforeId(strings) {
    return strings.map(str => {
        // Split the string into [name, id] by the underscore
        const parts = str.split('_');
        // Check if the split operation gave us exactly 2 parts
        if (parts.length === 2) {
            // Reconstruct the string with '-1' added before the ID
            return `${parts[0]}-1_${parts[1]}`;
        }
        // Return the original string if it doesn't match the expected format
        return str;
    });
}
