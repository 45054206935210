export default function createDefaultSession(assembly, tracks) {
    // Create the assembly (ReferenceSequenceTrack) first
    const assemblyName = assembly.substring(0, assembly.lastIndexOf('.'));
    const assemblyTrack = {
        id: `${assemblyName}`,
        type: 'ReferenceSequenceTrack',
        configuration: `${assemblyName}-ReferenceSequenceTrack`,
        displays: [{
            id: generateRandomId(),
            type: 'LinearReferenceSequenceDisplay',
            configuration: `${assemblyName}-ReferenceSequenceTrack-LinearReferenceSequenceDisplay`,
        }],
    };

    // Now create the rest of the tracks
    const trackObjects = tracks.map((file) => {
        const extension = file.split('.').pop();
        const fileBaseName = file.substring(0, file.lastIndexOf('.'));
        const trackId = `${fileBaseName}-${extension}`;
        const displayName = `${fileBaseName}`;

        switch(extension) {
            case 'vcf':
                let id = generateRandomId();
                return {
                    id: id,
                    type: 'VariantTrack',
                    configuration: displayName,
                    displays: [{
                        id: id,
                        type: 'LinearVariantDisplay',
                        configuration: `${displayName}-LinearVariantDisplay`,
                    }],
                };
            case 'gff':
            case 'ann2':
            case 'cov':
            case 'mq':
            case 'gff3':
                // console.log("session: ", displayName );
                let check = containsBlast( displayName );
                let notes_check = containsNotes( displayName );

                if( check ) {
                    // BlastJob
                    let id = generateRandomId();
                    return {
                        id: id,
                        type: 'FeatureTrack',
                        configuration: displayName+".gff",
                        displays: [{
                            id:id,
                            type: 'LinearBasicDisplay',
                            configuration: `${displayName}.gff-LinearBasicDisplay`,
                        }],
                    };
                } else if( notes_check ) {
                    // Notes GFF
                    let id = generateRandomId();
                    return {
                        id: id,
                        type: 'FeatureTrack',
                        configuration: displayName+".gff",
                        displays: [{
                            id: id,
                            type: 'LinearBasicDisplay',
                            configuration: `${displayName}.gff-LinearBasicDisplay`,
                        }],
                    };
                } else {
                    // Normal GFF
                    let id = generateRandomId();
                    return {
                        id: id,
                        type: 'FeatureTrack',
                        configuration: displayName,
                        displays: [{
                            id: id,
                            type: 'LinearBasicDisplay',
                            configuration: `${displayName}-LinearBasicDisplay`,
                        }],
                    };
                }
            case 'bam':
                return {
                    type: 'AlignmentsTrack',
                    configuration: displayName,
                    displays: [{
                        type: 'LinearAlignmentsDisplay',
                        configuration: {
                            type: 'LinearAlignmentsDisplay',
                            displayId: displayName,
                        },
                    }],
                };
            default:
                return null;
        }
    }).filter(track => track !== null);  // Filter out any null tracks

    // Combine assemblyTrack with trackObjects
    const allTracks = [assemblyTrack, ...trackObjects];

    return {
        name: `${assemblyName} session`,
        margin: 0,
        view: {
            id: 'linearGenomeView',
            type: 'LinearGenomeView',
            tracks: allTracks,
        },
    };
}


function generateRandomId() {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function containsBlast(str) {
    return str.includes("BlastJob");
}

function containsNotes(str) {
    return str.includes(".notes");
}