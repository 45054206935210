import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import JBrowse from "./components/JBrowse";
import NotFound from "./components/NotFound";
import { SavedSession } from "./components/SavedSession";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";

import { Buffer } from 'buffer'
window.Buffer = Buffer;

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let location = useLocation();

  const imgSrc = require("./img/logo.png");
  const style = { marginRight: "10px", height: "40px" }

  // Prevent Swipe
  useEffect(() => {
    // Push the current state into the history
    window.history.pushState(null, null, window.location.pathname);
    
    // Add an event listener for popstate
    window.addEventListener('popstate', handlePopState);
    
    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handlePopState = (event) => {
    // Push the state again to prevent the back/forward navigation
    window.history.pushState(null, null, window.location.pathname);
  };

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
    // navigate("/");
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <div className="bg-Verinomics">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">
          <img src={ imgSrc } alt="logo" height={50} style={style} />
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li>

          {/* {showModeratorBoard && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Admin Board
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          )} */}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/jbrowse"} className="nav-link">
                JBrowse Wizard
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/sessions"} className="nav-link">
                Saved Sessions
              </Link>
            </li>
          )}

        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                <PersonIcon />
                {/* <img src={currentUser.gravatar} alt="User Gravatar" className="w-6 h-6 rounded-full border-2 border-indigo-500 mr-1"/> */}
                {/* {currentUser.username} */}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/#/login" className="nav-link" onClick={logOut}>
                <LogoutIcon />
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container-fluid mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user" element={<BoardUser />} />
          <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} />
          <Route path="/jbrowse" element={ <JBrowse /> } />
          <Route path="/sessions" element={ <SavedSession/> } />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}

        </Routes>
      </div>

      <AuthVerify logOut={logOut}/>
    </div>
  );
};

export default App;
