// http.js
const getSocket = () => {
    let url;
    // console.log("enviroment: ", process.env.NODE_ENV );
    switch (process.env.NODE_ENV) {
        case 'production':
            // url = 'http://127.0.0.1:8080';
            url = 'https://www.jbrowse.verinomics.com';
            // url = 'http://192.168.12.127:8080';
            break;
        default:
            // url = 'http://localhost:8081';
            url = 'http://127.0.0.1:8080';
    }
    return url;
}

export default getSocket;
