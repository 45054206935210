import React, { useState, useEffect } from "react";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import JBrowseService from "../services/jbrowse.service";
import { TextField, Button, Box, MenuItem, Typography } from '@mui/material';

const PanGenomeModal = (props) => {
    // console.log('props: ', props );
    const { 
        setPanGenomeModalIsOpen,
        location,
        setLoading,
        currentUser,
        setAvailablePaf,
        availablePaf,
        selectedAssembly,
        setSelectedPaf,
        selectedPaf,
    } = props;

    const [ currentPaf, setCurrentPaf ] = useState("");

    const closeModal = () => {
        setPanGenomeModalIsOpen(false);
    };

    const handlePafSelect = (event) => {
        let paf = currentPaf;
        console.log("paf: ", paf );
        setSelectedPaf(selectedPaf => [...selectedPaf, paf]);
        setTimeout( () => {
            setPanGenomeModalIsOpen( false );
        },500);
    }

    useEffect(() => {
        if( currentUser ) {
            JBrowseService.getPafFiles().then(
                (response) => {
                    console.log("paf response: ", response );
                    setAvailablePaf( response )
                },
                (error) => {
                    console.log("error: ", error );
                }
            )
        }
    }, [currentUser]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setPanGenomeModalIsOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50">
                <div className="relative z-50 w-11/12 max-w-2xl p-6 bg-white rounded-lg shadow-lg md:w-3/4 lg:w-1/2">
                    <div className="flex items-center justify-between mb-4">
                        <h5 className="text-xl font-semibold">
                            <CompareArrowsIcon style={{ marginRight: "5px" }} /> Select Pan Genome
                        </h5>
                        <button type="button" className="close" onClick={()=> {setPanGenomeModalIsOpen(false)}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}> {/* Adjust max-height as needed */}

                    { availablePaf.length > 0 && (
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                                <i>Target:</i>  {selectedAssembly}
                            </Typography>

                            <TextField
                                select
                                label="Query"
                                value={currentPaf}
                                onChange={(e) => setCurrentPaf(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                {availablePaf.map((paf, key) => (
                                    <MenuItem key={key} value={paf}>
                                        {paf.Name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    )}

                    </div>
                    <div className="flex justify-end mt-4">
                        <button className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 mr-3" onClick={handlePafSelect}>
                            Add Synteny Track
                        </button>
                        <button className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PanGenomeModal;




