// http.js
const getBaseUrl = () => {
    let url;
    // console.log("enviroment: ", process.env.NODE_ENV );
    switch (process.env.NODE_ENV) {
        case 'production':
            // url = 'http://localhost:8080/';
            url = 'https://www.jbrowse.verinomics.com/';
            // url = 'http://192.168.12.127:8080/';
            break;
        default:
            // url = 'http://192.168.12.234:8080/';
            url = 'http://localhost:8080/';
    }
    return url;
}

export default getBaseUrl;
